import _ from "lodash";
import fetch from "cross-fetch";
import { createAction, RSAARequestType, RSAASuccessType, RSAAFailureType } from "redux-api-middleware";
import decamelizeKeysDeep from "decamelize-keys-deep";
import AppSettings from "../config";
import networkSelectors from "../selectors/network";

export const JWT_UPDATED = "JWT_UPDATED";
export const POST_LOGIN_LOADING = "LOGIN_LOADING";
export const POST_LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "LOGIN_FAILURE";
export const POST_SIGNUP_LOADING = "SIGNUP_LOADING";
export const POST_SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const POST_SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const POST_RESET_PASSWORD_LOADING = "POST_RESET_PASSWORD_LOADING";
export const POST_RESET_PASSWORD_SUCCESS = "POST_RESET_PASSWORD_SUCCESS";
export const POST_RESET_PASSWORD_FAILURE = "POST_RESET_PASSWORD_FAILURE";
export const POST_FORGOT_PASSWORD_LOADING = "POST_FORGOT_PASSWORD_LOADING";
export const POST_FORGOT_PASSWORD_SUCCESS = "POST_FORGOT_PASSWORD_SUCCESS";
export const POST_FORGOT_PASSWORD_FAILURE = "POST_FORGOT_PASSWORD_FAILURE";
export const POST_VERIFY_LOADING = "POST_VERIFY_LOADING";
export const POST_VERIFY_SUCCESS = "POST_VERIFY_SUCCESS";
export const POST_VERIFY_FAILURE = "POST_VERIFY_FAILURE";
export const POST_SEND_WELCOME_LOADING = "POST_SEND_WELCOME_LOADING";
export const POST_SEND_WELCOME_SUCCESS = "POST_SEND_WELCOME_SUCCESS";
export const POST_SEND_WELCOME_FAILURE = "POST_SEND_WELCOME_FAILURE";
export const GET_LOGIN_HISTORY_LOADING = "GET_LOGIN_HISTORY_LOADING";
export const GET_LOGIN_HISTORY_SUCCESS = "GET_LOGIN_HISTORY_SUCCESS";
export const GET_LOGIN_HISTORY_FAILURE = "GET_LOGIN_HISTORY_FAILURE";
export const GET_LOGIN_HISTORY_CLEAR = "GET_LOGIN_HISTORY_CLEAR";
export const PUT_PROFILE_LOADING = "PUT_PROFILE_LOADING";
export const PUT_PROFILE_SUCCESS = "PUT_PROFILE_SUCCESS";
export const PUT_PROFILE_FAILURE = "PUT_PROFILE_FAILURE";

export interface ILogin {
  email: string;
  password: string;
  site?: string;
}

export interface ISignup {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export function setJwt({ accessToken }: any) {
  return {
    type: JWT_UPDATED,
    payload: {
      accessToken,
    },
  };
}

export function getLoginHistory(id: string) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/${id}/logins`,
    method: "GET",
    headers: networkSelectors.defaultHeaders,
    types: _.map([GET_LOGIN_HISTORY_LOADING, GET_LOGIN_HISTORY_SUCCESS, GET_LOGIN_HISTORY_FAILURE], (type: string) => ({
      type,
      meta: { id },
    })) as [RSAARequestType, RSAASuccessType, RSAAFailureType],
  });
}

export function getLoginHistoryClear(id: string) {
  return (dispatch: any) =>
    dispatch({
      type: GET_LOGIN_HISTORY_CLEAR,
      meta: {
        id,
      },
    });
}

export function logout(site: string, isCitizen: boolean) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/sessions`,
    method: "DELETE",
    headers: !!site ? () => networkSelectors.defaultHeadersWithSite(site) : networkSelectors.defaultHeaders,
    types: [
      "LOGOUT_LOADING",
      {
        type: "LOGOUT_SUCCESS",
        payload: async () => {
          localStorage.removeItem("jwt.access_token");
          //default ot non-citizen php page
          let url = AppSettings.GOGOV_WEB_URL + "/" + site + "/logout.php?finalize=true";

          //redirect to landing page for local development
          if (AppSettings.LOCAL_DEV_ENV) {
            url = "/" + site + "/landing";
          }

          //if it is a citizen, go to public permits after logout
          if (isCitizen) {
            //go to public url
            url = "/" + site + "/public/permits";
          }

          //go to either url
          window.location.href = url;
        },
      },
      "LOGOUT_FAILURE",
    ],
  });
}

export function login({ email, password, site }: ILogin) {
  const body = { email, password };
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/sessions`,
    method: "POST",
    body: JSON.stringify(body),
    headers: !!site ? () => networkSelectors.defaultHeadersWithSite(site) : networkSelectors.defaultHeaders,
    types: [
      POST_LOGIN_LOADING,
      {
        type: POST_LOGIN_SUCCESS,
        payload: async (action, state, res) => {
          if (res.status === 200) {
            const json = await res.json();
            localStorage.setItem("jwt.access_token", json.access_token);
          }
        },
      },
      POST_LOGIN_FAILURE,
    ],
  });
}

export function signup(body: ISignup) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}`,
    method: "POST",
    body: JSON.stringify(body),
    headers: networkSelectors.defaultHeaders,
    types: [POST_SIGNUP_LOADING, POST_SIGNUP_SUCCESS, POST_SIGNUP_FAILURE],
  });
}

export function forgotPassword(body: { site: string; email: string; redirect?: string }) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/forgot`,
    method: "POST",
    body: JSON.stringify(decamelizeKeysDeep(body, "_")),
    headers: networkSelectors.defaultHeaders,
    types: [POST_FORGOT_PASSWORD_LOADING, POST_FORGOT_PASSWORD_SUCCESS, POST_FORGOT_PASSWORD_FAILURE],
  });
}

export function sendWelcome(body: { site: string; email: string; token?: string }) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/welcome`,
    method: "POST",
    body: JSON.stringify(decamelizeKeysDeep(body, "_")),
    headers: networkSelectors.defaultHeaders,
    types: [POST_SEND_WELCOME_LOADING, POST_SEND_WELCOME_SUCCESS, POST_SEND_WELCOME_FAILURE],
  });
}

export function resetPassword(body: {
  site: string;
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
}) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/reset`,
    method: "POST",
    body: JSON.stringify(decamelizeKeysDeep(body, "_")),
    headers: networkSelectors.defaultHeaders,
    types: [POST_RESET_PASSWORD_LOADING, POST_RESET_PASSWORD_SUCCESS, POST_RESET_PASSWORD_FAILURE],
  });
}

export function verify(body: {
  site: string;
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
}) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/verify`,
    method: "POST",
    body: JSON.stringify(decamelizeKeysDeep(body, "_")),
    headers: networkSelectors.defaultHeaders,
    types: [POST_VERIFY_LOADING, POST_VERIFY_SUCCESS, POST_VERIFY_FAILURE],
  });
}

export const getUser = (networkHeaders: any) => () =>
  fetch(`${AppSettings.IDENTITY_API_URL}/me`, {
    method: "GET",
    headers: networkHeaders,
  }).then((res) => {
    if (res.status === 200) {
      return res.json();
    }
    throw new Error(`Query failed with status ${res.status}`);
  });

export function updateProfile(body: { firstName: string; lastName: string; company_name?: string }) {
  return createAction({
    endpoint: `${AppSettings.IDENTITY_API_URL}/me`,
    method: "PUT",
    body: JSON.stringify(body),
    headers: networkSelectors.defaultHeaders,
    types: [
      PUT_PROFILE_LOADING,
      {
        type: PUT_PROFILE_SUCCESS,
        payload: async (action, state, res) => {
          if (res.status === 200) {
            const json = await res.json();
            localStorage.setItem("jwt.access_token", json.access_token);
          }
        },
      },
      PUT_PROFILE_FAILURE,
    ],
  });
}
