import { ContentType } from "@gogovapps/core-service-client/api";
import { coreService } from "../services/coreService";
import { FirstArgumentType, SecondArgumentType } from "../types/ArgumentTypes";
import { buildQuery } from "../utils/functions";

export const postNotifyInvite = (headers: any) => (body: FirstArgumentType<typeof coreService.notifyInviteCreate>) => {
  return buildQuery(coreService.notifyInviteCreate(body, { headers }), (response) => {
    return response.data;
  });
};

export const getNotifySMSNotificationInfo = (headers: any) => () => {
  return buildQuery(coreService.notifySmsNotificationInfoList({ headers }), (response) => {
    return response.data;
  });
};

export const getNotifyVoiceNotificationInfo =
  (headers: any) => (queryParameters: FirstArgumentType<typeof coreService.notifyVoiceNotificationInfoList>) => {
    return buildQuery(coreService.notifyVoiceNotificationInfoList(queryParameters, { headers }), (response) => {
      return response.data;
    });
  };

export const getPublicNotifySettings = (headers: any) => () => {
  return buildQuery(coreService.notifySettingsPublicList({ headers }), (response) => {
    return response.data;
  });
};

export const getNotifySettings = (headers: any) => () => {
  return buildQuery(coreService.notifySettingsList({ headers }), (response) => {
    return response.data;
  });
};

export type NotifySettings = Awaited<ReturnType<typeof coreService.notifySettingsList>>["data"];

export const putNotifySettings =
  (headers: any) => (body: FirstArgumentType<typeof coreService.notifySettingsUpdate>) => {
    return buildQuery(coreService.notifySettingsUpdate(body, { headers }), (response) => {
      return response;
    });
  };

export type PutNotifySettingsBody = FirstArgumentType<typeof coreService.notifySettingsUpdate>;

export const postVoiceNotificationGeneration =
  (headers: any) => (body: FirstArgumentType<typeof coreService.notifyVoiceNotificationGenerateCreate>) => {
    return buildQuery(coreService.notifyVoiceNotificationGenerateCreate(body, { headers }), (response) => {
      return response.data;
    });
  };

export const getPreGeneratedVoiceAudioResources = (headers: any) => () => {
  return buildQuery(coreService.notifyVoiceAudioResourcePreGeneratedList({ headers }), (response) => {
    return response.data;
  });
};

export const putPreGeneratedVoiceAudioResourcesGeneration = (headers: any) => () => {
  return buildQuery(
    coreService.notifyVoiceAudioResourcePreGeneratedGenerateUpdate({ headers, type: ContentType.Text }),
    (response) => {
      return response.data;
    },
  );
};

export type GetNotifySubscriptionGroupsParamsType = { cursor?: number; includeDeactivated?: boolean; take?: number };
export const getNotifySubscriptionGroups =
  (headers: any) =>
  ({ cursor, take, includeDeactivated }: GetNotifySubscriptionGroupsParamsType) => {
    return buildQuery(
      coreService.notifySubscriptionGroupList(
        {
          cursor,
          includeDeactivated: includeDeactivated ? "true" : "false",
          take,
        },
        { headers },
      ),
      (response) => {
        return response.data;
      },
    );
  };

export type GetNotifySubscriptionGroupResponse = Awaited<
  ReturnType<typeof coreService.notifySubscriptionGroupDetail>
>["data"];
export const getNotifySubscriptionGroup =
  (headers: any) =>
  ({ id }: { id: number }) => {
    return buildQuery(coreService.notifySubscriptionGroupDetail(id, { headers }), (response) => {
      return response.data;
    });
  };

export type CreateNotifySubscriptionGroupBody = FirstArgumentType<typeof coreService.notifySubscriptionGroupCreate>;
export type CreateNotifySubscriptionGroupResponse = Awaited<
  ReturnType<typeof coreService.notifySubscriptionGroupCreate>
>["data"];
export const postNotifySubscriptionGroup =
  (headers: any) => (body: FirstArgumentType<typeof coreService.notifySubscriptionGroupCreate>) => {
    return buildQuery(coreService.notifySubscriptionGroupCreate(body, { headers }), (response) => {
      return response.data;
    });
  };

export type UpdateNotifySubscriptionGroupBody = SecondArgumentType<typeof coreService.notifySubscriptionGroupUpdate>;
export type UpdateNotifySubscriptionGroupResponse = Awaited<
  ReturnType<typeof coreService.notifySubscriptionGroupUpdate>
>["data"];
export const putNotifySubscriptionGroup =
  (headers: any) =>
  ({ id, body }: { id: number; body: SecondArgumentType<typeof coreService.notifySubscriptionGroupUpdate> }) => {
    return buildQuery(coreService.notifySubscriptionGroupUpdate(id, body, { headers }), (response) => {
      return response.data;
    });
  };

export type DeactivateNotifySubscriptionGroupResponse = Awaited<
  ReturnType<typeof coreService.notifySubscriptionGroupDeactivatedUpdate>
>["data"];
export const deactivateNotifySubscriptionGroup =
  (headers: any) =>
  ({ id }: { id: number }) => {
    return buildQuery(
      coreService.notifySubscriptionGroupDeactivatedUpdate(id, { headers, type: ContentType.Text }),
      (response) => {
        return response.data;
      },
    );
  };

export type ReactivateNotifySubscriptionGroupResponse = Awaited<
  ReturnType<typeof coreService.notifySubscriptionGroupDeactivatedDelete>
>["data"];
export const reactivateNotifySubscriptionGroup =
  (headers: any) =>
  ({ id }: { id: number }) => {
    return buildQuery(coreService.notifySubscriptionGroupDeactivatedDelete(id, { headers }), (response) => {
      return response.data;
    });
  };
